import revive_payload_client_bLQinAvJuL from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_r56wTORZ0p from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1X6D2dp7d4 from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_zxy3x8OOlS from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cnEZt7tgxn from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IcoTAJvHX6 from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wDZVkyqkC3 from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/.nuxt/components.plugin.mjs";
import prefetch_client_BRaBDuHalZ from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.7.4_eslint@9.11.1_sass@1.77.8_stylelint@16.9.0_typescript@5.6.2_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_vT9JWWT9pN from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/auth.ts";
import autofocus_1LURlzQ7IF from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/autofocus.ts";
import chart_client_qQ92gke75l from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/chart.client.ts";
import dayjs_pGiXRjcsJO from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/dayjs.ts";
import dsfr_client_wnEKqTCG96 from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/dsfr.client.js";
import global_event_emitter_AhsutoD9go from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/global-event-emitter.ts";
import matomo_client_Ed2EqINkhd from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/matomo.client.ts";
import modal_UEFnUXzgTX from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/modal.ts";
import refresh_app_client_BPM4AstEHA from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/refresh-app.client.ts";
import sentry_client_shVUlIjFLk from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/sentry.client.ts";
import trpcClient_M8UiGTyzsx from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/plugins/trpcClient.ts";
export default [
  revive_payload_client_bLQinAvJuL,
  unhead_r56wTORZ0p,
  router_1X6D2dp7d4,
  payload_client_zxy3x8OOlS,
  navigation_repaint_client_cnEZt7tgxn,
  check_outdated_build_client_IcoTAJvHX6,
  chunk_reload_client_wDZVkyqkC3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BRaBDuHalZ,
  auth_vT9JWWT9pN,
  autofocus_1LURlzQ7IF,
  chart_client_qQ92gke75l,
  dayjs_pGiXRjcsJO,
  dsfr_client_wnEKqTCG96,
  global_event_emitter_AhsutoD9go,
  matomo_client_Ed2EqINkhd,
  modal_UEFnUXzgTX,
  refresh_app_client_BPM4AstEHA,
  sentry_client_shVUlIjFLk,
  trpcClient_M8UiGTyzsx
]