import { default as emailsyYscDiZShzMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/emails.vue?macro=true";
import { default as integrations11ZQ2AW6EdMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/integrations.vue?macro=true";
import { default as overviewkH9j7PqzsAMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/overview.vue?macro=true";
import { default as indexp4bp3wndapMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsw2cawkOuXjMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexf0PfAxQfAnMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/structures/index.vue?macro=true";
import { default as indexnjOf644NYDMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/users/[id]/index.vue?macro=true";
import { default as addtL1BJ06z4HMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/users/add.vue?macro=true";
import { default as indexMOAcNTl4NlMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/users/index.vue?macro=true";
import { default as adminIDwhCWhc5qMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin.vue?macro=true";
import { default as accountkZcWk5ES7ZMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/account.vue?macro=true";
import { default as entourageIqMzQ2FoElMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsWz9QJnMoLqMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthNrFkcjRvwKMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoG6OiafFOmqMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationYgEHyusu6FMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdBWFbHITJXlMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printSGv3wVmctoMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexjgzwOSEAjoMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/index.vue?macro=true";
import { default as editsHB00D4tpJMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as edit1aMoJFWA1SMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editkuqxYmfBj3Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addTrSTkhs1ANMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editiammJ9HKAZMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addTjIy6bgm5ZMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editEYqJAgRDcBMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addDhEGRemmZwMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editTcc1PHGQfkMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexW0YiIpu5aFMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/index.vue?macro=true";
import { default as addWQFj2ldaAQMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/add.vue?macro=true";
import { default as historyAQ3Zrf5yfGMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/history.vue?macro=true";
import { default as printMnqVgDZFuoMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewm9W42Zvm7gMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/overview.vue?macro=true";
import { default as partnersRturOkOVbqMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/partners.vue?macro=true";
import { default as statsDfZatxqqf7Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/stats.vue?macro=true";
import { default as indext3ISO1fEhdMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/structure/index.vue?macro=true";
import { default as subjectsIQZ8xXqDCeMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/structure/subjects.vue?macro=true";
import { default as usersku0pDrElsQMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/users.vue?macro=true";
import { default as applhCmgpZLmXMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app.vue?macro=true";
import { default as adminS80zXVWOg7Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/admin.vue?macro=true";
import { default as loginOTAiFawXmSMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/login.vue?macro=true";
import { default as logoutaWMzXrYkvcMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/logout.vue?macro=true";
import { default as proconnectjQoszmKidWMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/proconnect.vue?macro=true";
import { default as validate_45token5QRHhpDtuKMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/validate-token.vue?macro=true";
import { default as managermpHRbSU1TUMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/cgu/manager.vue?macro=true";
import { default as userQ62ZftgExYMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/cgu/user.vue?macro=true";
import { default as cgufzeJZIL3r0Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitel8xlh5mWf3Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountyEqGpYebuKMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45userW0wAMMrhw5Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/error/unknown-user.vue?macro=true";
import { default as indexR5dvdRnbD1Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/index.vue?macro=true";
import { default as mentions_45legalesOTKXYTChX6Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteVDDpcS3mymMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/politique-confidentialite.vue?macro=true";
import { default as registerFAkaWG2NNWMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/register.vue?macro=true";
import { default as statistiqueskk2ORMYRwpMeta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/statistiques.vue?macro=true";
import { default as storiesWw6GBFqjY6Meta } from "/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminIDwhCWhc5qMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overviewkH9j7PqzsAMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewkH9j7PqzsAMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: applhCmgpZLmXMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationYgEHyusu6FMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationYgEHyusu6FMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printSGv3wVmctoMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: indexjgzwOSEAjoMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexjgzwOSEAjoMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: editsHB00D4tpJMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editsHB00D4tpJMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: edit1aMoJFWA1SMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: edit1aMoJFWA1SMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editTcc1PHGQfkMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editTcc1PHGQfkMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printMnqVgDZFuoMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewm9W42Zvm7gMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewm9W42Zvm7gMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminS80zXVWOg7Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginOTAiFawXmSMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutaWMzXrYkvcMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-proconnect",
    path: "/auth/proconnect",
    meta: proconnectjQoszmKidWMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/proconnect.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45token5QRHhpDtuKMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cgufzeJZIL3r0Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitel8xlh5mWf3Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountyEqGpYebuKMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userW0wAMMrhw5Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexR5dvdRnbD1Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesOTKXYTChX6Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteVDDpcS3mymMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerFAkaWG2NNWMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiqueskk2ORMYRwpMeta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesWw6GBFqjY6Meta || {},
    component: () => import("/build/40f7e1a6-d9e8-405f-a62c-0cb218f13e23/pages/stories.vue").then(m => m.default || m)
  }
]